<template>
  <a-modal v-model="visible" :title="form.ru.name" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>

    <a-form-model :model="form">
      <a-form-model-item class="text_area" label="Логотип">
        <a-upload
          list-type="picture-card"
          :file-list="fileListMain"
          @change="handleChangeList"
        >
          <div v-if="fileListMain.length < 1">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              Upload
            </div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="Название(ru)" required>
        <a-input  v-model.number="form.ru.name"/>
      </a-form-model-item>
      <a-form-model-item label="Название(ua)" required>
        <a-input  v-model.number="form.ua.name"/>
      </a-form-model-item>

      <a-form-model-item  v-if="!form.ru.description.includes('<')" label="Описание(ru)" required>
        <a-input  v-model.number="form.ru.description"/>
      </a-form-model-item>
      <a-form-model-item v-if="!form.ua.description.includes('<')" label="Описание(ua)" required>
        <a-input  v-model.number="form.ua.description"/>
      </a-form-model-item>

      <a-form-model-item v-if="form.ru.description.includes('<')" class="text_area" label="Описание(ru)" required>
        <quill-editor ref="quillEditor" v-model="form.ru.description" :disabled="$route.query.is_preview" />
      </a-form-model-item>
      <a-form-model-item v-if="form.ru.description.includes('<')" class="text_area" label="Описание(ua)" required>
        <quill-editor ref="quillEditor" v-model="form.ua.description" :disabled="$route.query.is_preview" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import users from '@/mixins/users'
import { quillEditor } from 'vue-quill-editor'
import { getBase64 } from '@/utils'

export default {
  name: 'MainBlocksSettings',

  components: {
    quillEditor,
  },

  mixins: [users],

  data() {
    return {
      form: {
        ru: {
          name: '',
          description: '',
        },
        ua: {
          name: '',
          description: '',
        },
      },
      visible: false,
      fileListMain: [],
    }
  },

  computed: {
    isDisabled() {
      const form = this.form
      return !(form.ru.name && form.ua.name && form.ru.description && form.ua.name && this.fileListMain.length)
    },
  },

  methods: {
    open(record) {
      this.form = record
      this.fileListMain.push({
        url: record.icon,
        uid: '-4',
        name: 'image.png',
        status: 'done',
      })
      this.visible = true
    },

    async submit() {
      if (this.fileListMain.length && this.fileListMain[0].originFileObj) {
        this.form.image = await getBase64(this.fileListMain[0].originFileObj)
      }

      try {
        await this.$services.put(`admin/block_pages/${this.form.id}`, this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },

    close() {
      this.visible = false
      this.$emit('getList')
      this.fileListMain = []
      this.form = {
        ru: {
          name: '',
          description: '',
        },
        ua: {
          name: '',
          description: '',
        },
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
