<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :row-key="(record, index) => index">
      <a
        slot="id"
        slot-scope="text"
        href="javascript: void(0);"
        class="btn btn-sm btn-light"
      >
        {{text}}
      </a>
      <span slot="name" slot-scope="record"> {{ record.ru ? record.ru.name : 'Сообщество AutoSill' }}</span>
      <span slot="action" slot-scope="record">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="openModal(record)">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
      </span>
    </a-table>
    <main-blocks-settings ref="blocks" @getList="$emit('getList')" />
    <main-community-settings ref="communities" @getList="$emit('getList')" />
    <main-products-settings ref="main_products" @getList="$emit('getList')" :marks="marks" />
  </div>
</template>

<script>
import settings from '@/entities/settings'
import MainBlocksSettings from '@/components/content/pages/main/MainBlocksSettings'
import MainCommunitySettings from '@/components/content/pages/main/MainCommunitySettings'
import MainProductsSettings from '@/components/content/pages/main/MainProductsSettings'

export default {
  name: 'MainPageTable',

  components: {
    MainBlocksSettings,
    MainCommunitySettings,
    MainProductsSettings,
  },

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      settings,
      marks: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название блока',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },

  created() {
    this.getMarks()
  },

  methods: {
    async getMarks() {
      try {
        this.marks = (await this.$services.get('/filters/marks?type=1')).data.data.marks
      } catch (e) {
        console.log(e)
      }
    },
    openModal(record) {
      if (record.alias) {
        this.$refs[record.alias].open(record)
      } else {
        this.$refs.blocks.open(record)
      }
    },
  },
}
</script>
