<template>
  <a-modal v-model="visible" title="Свежие объявления" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>

    <a-form-model :model="form">
      <a-form-model-item label="Количество" required>
        <a-input  v-model.number="form.paginate" />
      </a-form-model-item>
      <a-form-model-item label="Название(ru)" required>
        <a-input  v-model.number="form.ru.name" />
      </a-form-model-item>
      <a-form-model-item label="Название(ua)" required>
        <a-input  v-model.number="form.ua.name" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'MainProductsSettings',

  data() {
    return {
      form: {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      },
      visible: false,
    }
  },

  computed: {
    isDisabled() {
      const form = this.form
      return !(form.paginate && form.ru.name && form.ru.name)
    },
  },

  methods: {
    open(record) {
      if (record.date_from && record.date_to) {
        record.period = [moment(record.date_from), moment(record.date_to)]
      } else {
        record.period = ''
      }
      this.form = record
      this.visible = true
    },
    async submit() {
      if (this.form.period) {
        this.form.date_from = this.form.period[0].format('YYYY-MM-DD')
        this.form.date_to = this.form.period[1].format('YYYY-MM-DD')
        delete this.form.period
      }

      try {
        await this.$services.put('admin/main/main_product', this.form)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.$emit('getList')
      this.fileListMain = []
      this.form = {
        ru: {
          name: '',
        },
        ua: {
          name: '',
        },
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
