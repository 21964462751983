<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Страницы сайта</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Главная</h5>
        </div>
      </div>
      <div class="card-body">
        <main-page-table :list="list" @getList="getList" />
      </div>
    </div>
  </div>
</template>

<script>
import MainPageTable from '@/components/content/pages/main/MainPageTable'

export default {
  name: 'index',
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const data = (await this.$services.get('admin/main')).data.data.data
        data.communities.alias = 'communities'
        data.main_products.alias = 'main_products'
        this.list = [...data.block_pages, data.communities, data.main_products]
      } catch (e) {
        console.log(e)
      }
    },
  },
  components: { MainPageTable },
}
</script>

<style scoped>

</style>
